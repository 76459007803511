nav {
    border-bottom: solid whitesmoke 2px;

    div.navbar-brand {

        align-items: center;
        justify-content: space-between;

        img {
            max-height: 8rem;
            margin-left: 0.25rem;
        }
    }

    div.navbar-menu {
        .navbar-item:not(.has-dropdown),
        .navbar-item.has-dropdown > span {
            font-weight: 550;
        }

        a {
            font-size: 1.25rem;
        }
    }

    #hamburger {

        color: white;
        background-color: #BD0F7F;
        border-radius:5px;

        margin-top: 0rem;
        margin-right: 1rem;
        display:block;
    }

    #hamburger:hover {
        background-color: #b10e77;
    }

    #hamburger:active {
        background-color: #a50d6f;
    }

    #hamburger:focus {
        outline: -webkit-focus-ring-color auto 0px;
        outline-color: -webkit-focus-ring-color;
        outline-style: auto;
        outline-width: 0px;
    }

    .is-selected {
        border-left: solid 10px $sitya-pink;
    }

    .is-not-selected {
        border-left: solid 10px white;
    }
}

@media (min-width: 1023px) {
    nav {
        div.navbar-brand {
            img {
                max-height: 8rem;
                margin-left: 0rem;
            }
        }

        div.navbar-menu {
            a {
                font-size: 1.25rem;
            }
        }

        #hamburger {
            display: none;
        }

        .is-selected {
            border-left: none 0px;
            border-top: solid 5px white;
            border-bottom: solid 5px $sitya-pink;
        }

        .is-not-selected {
            border-left: none 0px;
            border-top: solid 5px white;
            border-bottom: solid 5px white;
        }
    }
}

@media (min-width: 1024px) {
    nav .navbar-end {
        max-width: calc(960px - 10rem);
        flex-wrap: wrap;
    }
}

@media (min-width: 1024px) {
    nav .navbar-end {
        max-width: calc(960px - 10rem);
        flex-wrap: wrap;
    }
}

@media (min-width: 1216px) {
    nav .navbar-end {
        max-width: calc(1152px - 10rem);
        flex-wrap: wrap;
    }
}

@media (min-width: 1408px) {
    nav .navbar-end {
        max-width: calc(1344px - 10rem);
        flex-wrap: wrap;
    }
}
