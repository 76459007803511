section.container {
    padding-left: 1rem;
    padding-right: 1rem;
}

.is-visible-desktop {
    display: none;
}

.is-header {
    font-size: 2rem;
    font-weight: bolder;
    color: $sitya-dark-gray;
}

.is-sub-header {
    font-size: 1.5rem;
    color: $sitya-dark-gray;
}

.is-larger-text {
    font-size: 1.25rem;
}

.is-text {
    font-size: 1.25rem;
}

body {
    font-size: 1.25rem;
}

div.greenline {
    border-top: 3px solid #A0CB55;
}

/*
Styling for tabs, as a link (as it should be) leads to a warning message in react
...and an # within the url
*/

.tabs {
    span.tablink {
        cursor: pointer;
        align-items: center;
        border-bottom-color: #dbdbdb;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        color: #762282;
        display: flex;
        justify-content: center;
        margin-bottom: -1px;
        padding: 0.5em 1em;
        vertical-align: top;
    }

    span.tablink:hover {
        color: #BD0F7F;
    }

    li.is-active {

        span.tablink {
            border-bottom-color: #BD0F7F;
            color: #BD0F7F;
        }
    }
}

.tabs::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.tabs::-webkit-scrollbar-track {
    background-color: transparent;
}

.tabs::-webkit-scrollbar-thumb {
    background-color: #762282;
    border-radius: 0px;
    border: none;
    background-clip: content-box;
}

.tabs::-webkit-scrollbar-thumb:hover {
    background-color: #BD0F7F;
}

.is-boxed {
    span.tablink {
        border: 1px solid transparent;
        border-radius: 4px 4px 0 0;
    }

    li.is-active {

        span.tablink {
            background-color: white;
            border-color: #dbdbdb;
            border-bottom-color: transparent !important;
        }
    }
}

@media (max-width: $tablet) {

    .footer {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    section {
        .card {

            border-radius: 0;
            box-shadow: none;

            padding: 1rem 0 0;

            .card-content {
                padding: 0;
            }
        }
    }
}

@media (min-width: $tablet) {

    .is-header {
        font-size: 2rem;
        color: $sitya-dark-gray;
        font-weight: bolder;
    }
    
    .is-sub-header {
        font-size: 1.5rem;
        color: $sitya-dark-gray;
    }
    
    .is-larger-text {
        font-size: 1.25rem;
    }
    
    .is-text {
        font-size: 1.25rem;
    }

    body {
        font-size: 1.25rem;
    }
}

@media (min-width: $desktop) {
    section.container {
        padding-left: 0;
        padding-right: 0;
    }

    #logincol {
        padding-top: 2rem;
        padding-left: 4rem;
    }
    
    .is-visible-desktop {
        display: flex;
    }

    .modal-card {
        width: 800px;
    }
}

@media (min-width: $widescreen) {
    .modal-card {
        width: 1024px;
    }
}
