$sitya-green: #A0CB55;
$sitya-orange: #EC6608;
$sitya-pink:#BD0F7F;
$sitya-lila: #762282;
$sitya-dark-gray: #444;

$primary: $sitya-pink;
$link: $sitya-lila;
$text: black;

$table-head-cell-color: $sitya-dark-gray;

$modal-card-head-radius: 0px;
$modal-card-foot-radius: 0px;

$navbar-item-color: $sitya-lila;
$navbar-dropdown-arrow: $sitya-lila;
$navbar-dropdown-item-hover-color: $sitya-pink;
$navbar-item-hover-color: $sitya-pink;

$tabs-link-active-border-bottom-color: $sitya-pink;
$tabs-link-active-color: $sitya-pink;
$tabs-link-hover-color: $sitya-pink;
$tabs-link-color: $sitya-lila;

$hamburger-layer-color: white;
$hamburger-hover-opacity: 1;

$pretty--color-primary: #428bca;

@import './../node_modules/bulma/bulma.sass';

@import './../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss';
@import './../node_modules/pretty-checkbox/src/pretty-checkbox.scss';

@import './scss/content.scss';
@import './scss/navbar.scss';

html {
    font-size: 13px;
}

span.link {
    color: $sitya-lila;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.25rem;
}

span.link:hover {
    color: $sitya-pink;
}

span.menulink:hover {
    background-color: $background;
}

a:hover {
    color: $sitya-pink;
}

.is-bold {
    font-weight: bolder;
}

.is-sitya-green {
    color: $sitya-green;
}

.is-sitya-lila {
    color: $sitya-lila;
}

.is-sitya-pink {
    color: $sitya-pink;
}

.is-sitya-orange {
    color: $sitya-orange;
}

.is-sitya-dark-gray {
    color: $sitya-dark-gray;
}