section.downloadview {

    .is-w-100 {
        width: 100% !important;
    }

    a.fileitem {
        margin-left: 0px;
    }

    @media (min-width: 769px) {
        a.fileitem {
            margin-left: 1rem;
        }
    }

    .is-border-bottom-none {
        border-bottom: 0px none !important;
    }

    .pointer {
        cursor: pointer;
    }

    td {
        padding-left: 0px !important;
        padding-right: 0px !important;
        color: #762282;
    }

    td.is-sitya-selected {
        font-weight: bold;
        color: #BD0F7F;
        border-left: solid 5px #BD0F7F !important;

        a {
            color:#BD0F7F;
        }
    }

    td.is-sitya-not-selected {
        border-left: solid 5px white
    }

    th {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    td:hover {
        background-color: #fafafa;
        color: #BD0F7F;
    }
}