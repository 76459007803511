#login {

    padding-top: 2rem;
    padding-bottom: 2rem;
}

#image {
    
    background-image: url('./login-downloadbereich-sitya.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 1024px) {

    #login {
        padding-top: 2rem;
        padding-bottom: 2rem;

        min-height: calc(100vh - 14rem);

        form {
            padding-left: 2rem;
        }

        #image {
            min-height: calc(100vh - 14rem);
        }
    }
}