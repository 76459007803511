#dashboard {
   
    padding-top: 2rem; 
    padding-bottom: 2rem;
 
    #welcome {
 
        display:flex;
        align-items: flex-end;

        #avatar {
            font-size: 5rem;

            .fa-primary {
                opacity: 0.7;
            }
            .fa-secondary {
                fill: #A0CB55;
                opacity: 1;
            }
        }

        #avatar-container {
            width: 4rem;
            height: 6rem;
        }

        #name {
            padding-left: 2rem;
        }
    }
}